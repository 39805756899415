<!--
 * @Descripttion: 预览功能插件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月23日14:55:10
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月23日14:56:11
 -->
<template>
  <transition name="bounce">
    <div class="preview" v-show="layer.show" :class="{ 'active': layer.show }" :style="{ 'top': layer.position.y, 'left': layer.position.x }">
      <div class="mask"></div>
      <!-- 顶部数据区 -->
      <div class="header">
        <div class="left">
          <i class="iconfont filedelete" @click="close"></i>
          <h3>{{ layer.active.name }}</h3>
        </div>
        <div class="right">
          <i class="iconfont fileShapecopy" title="下载" @click="download"></i>
          <i class="iconfont filehuishouzhan" title="删除"></i>
        </div>
      </div>
     
      <div class="content">
        <!-- 图片展示区 -->
        <div class="main">
          <div
            class="preview-box"
            ref="preview"
            @mousedown='mousedown($event)'
            :style="{ 'transform': 'scale(' + scale + ') rotate(' + rotate + 'deg)', 'marginLeft': marginLeft + 'px', 'marginTop': marginTop + 'px' }"
          >
            <img :src="layer.active.fileVisitUrl" :onerror="errorGoodsImg" :alt="layer.active.name">
          </div>
        </div>
        <!-- 左切换按钮 -->
        <div class="left button" @click="previousOne" v-if="list.length > 1" :class="{ 'disabled': layer.active === list[0] }">
          <i class="el-icon-arrow-left"></i>
        </div>
        <!-- 右切换按钮 -->
        <div class="right button" @click="nextOne" v-if="list.length > 1" :class="{ 'disabled': layer.active === list[list.length - 1] }">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    components: {

    },
    props: {
      'layer': {
        type: Object,
        default: () => {
          return {
            show: false,
            position: {
              x: 0,
              y: 0
            },
            active: {}
          }
        }
      },
      'list': {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        // 当前缩放大小
        scale: 1,
        errorGoodsImg:'this.src="' + require('../../assets/images/404.jpg') + '"',
        // 当前旋转大小
        rotate: 0,
        // 左移距离
        marginLeft: 0,
        // 上移距离
        marginTop: 0
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.handleMouse()
      this.handleESC()
      this.handleLeftRight()
    },
    methods: {
      // 监听鼠标滚动事件
      handleMouse() {
        let dom = this.$refs.preview
        dom.onmousewheel = (event) => {
          if (event.wheelDelta === 120) {
            this.scale = this.scale + 0.1
          } else {
            if (this.scale > 0.2) {
              this.scale = this.scale - 0.1
            }
          }
        }
      },
      // 鼠标拖动事件
      mousedown(e) {
        e.preventDefault()
        let startX = e.clientX
        let startY = e.clientY
        document.onmousemove = (ev) => {
          this.marginLeft = (ev.clientX - startX) * 2 + this.marginLeft
          this.marginTop = (ev.clientY - startY) * 2 + this.marginTop
          startX = ev.clientX
          startY = ev.clientY
        }
        document.onmouseup = (ev) => {
          document.onmousemove = null
          document.onmouseup = null
        }
      },
      // 监听ESC退出功能
      handleESC() {
        document.addEventListener('keydown', (e) => {
          if (e.keyCode === 27) {
            this.close()
          }
        })
      },
      // 监听左右方向键切换active功能
      handleLeftRight() {
        document.addEventListener('keydown', (e) => {
          if (e.keyCode === 37) {
            // 监听左键
            this.previousOne()
          } else if (e.keyCode === 39) {
            // 监听右键
            this.nextOne()
          }
        })
      },
      // 上一个
      previousOne() {
        let data = this.list
        for (const i in data) {
          if (data[i] === this.layer.active) {
            if (parseInt(i) !== 0) {
              this.layer.active = data[parseInt(i) - 1]
            }
            return
          }
        }
      },
      // 下一个
      nextOne() {
        let data = this.list
        for (const i in data) {
          if (data[i] === this.layer.active) {
            if (parseInt(i) !== data.length - 1) {
              this.layer.active = data[parseInt(i) + 1]
            }
            return
          }
        }
      },
      // 关闭功能
      close() {
        this.layer.show = false
      },
      // 下载功能
      download() {
        window.open(this.layer.active.fileDownloadUrl)
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .preview{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      width: 100vw;
      height: 100vh;
      opacity: 0.8;
      z-index: -1;
    }
    .header{
      background-color: #000;
      height: 84px;
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left{
        display: flex;
        align-items: center;
        padding-left: 36px;
        h3{
          color: #fff;
          font-size: 20px;
          padding-left: 23px;
          font-family:Microsoft YaHei;
          font-weight: normal;
        }
      }
      .right{
        padding-right: 100px;
        .iconfont{
          padding-right: 36px;
        }
      }
      .iconfont{
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        transition: 0.2s;
        &:hover{
          color: #04a7ff;
        }
      }
    }
    .content{
      width: 100vw;
      height: calc(100vh - 84px);
      .main{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .button{
        width: 86px;
        height: 86px;
        background-color: rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        border-radius: 86px;
        position: fixed;
        top: calc(50vh - 43px);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.disabled {
          background-color: #2f2626 !important;
          cursor: not-allowed;
          i{
            color: #eee;
          }
        }
        &:hover{
          background-color: #04a7ff;
        }
        i{
          color: #fff;
          font-size: 45px;
        }
      }
      .left{
        left: 148px;
      }
      .right{
        right: 148px;
      }
    }
  }
  .preview-box{
    transition: transform 0.2s;
  }
  .bounce-enter-active{
    animation: mask2show .5s;
  }
  .bounce-leave-active{
    animation: mask2show .5s reverse;
  }
  @keyframes mask2show{
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
